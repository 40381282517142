<template>
  <div class="fwtc">
    <div class="main">
      <div v-for="item in taocan_order" :key="item.id">
        <div class="box" v-if="item.type == '2'">
          <div class="head">
            <div class="title">
              <div class="top">
                <van-button size="mini" type="primary">{{ item.taocan_data?.store_data?.store_name || '暂无门店' }}</van-button>
              </div>
              <div>{{ item.taocan_data.mc }}</div>
            </div>
            <div class="num">
              <span class="red">{{ (item.xfcs - item.sycs) }}</span>
              <span class="sum">/{{ item.xfcs }}</span>
            </div>
            <VanButton type="primary" size="small" :loading="item.loading" @click="tapSubmit(item)">消费</VanButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { showConfirmDialog, showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Fwxf',
  data() {
    return {
    }
  },
  created() {
    this.get_taocan_order({
      sjhm: this.$route.query.sjhm
    });
  },
  computed: {
    ...mapState(['taocan_order']),
  },
  methods: {
    ...mapActions(['get_taocan_order', 'taocan_xiaofei']),
    tapSubmit(record) {
      showConfirmDialog({
        title: '提示',
        message:
          '是否确认消费一次[' + record.taocan_data.mc + ']?',
      })
        .then(async () => {
          // on confirm
          record.loading = true;
          let { data: res } = await this.taocan_xiaofei({ sjhm: this.$route.query.sjhm, sfbs: this.$route.query.sfbs, order_id: record.id })
          record.loading = false;
          if (res.success) {
            showNotify({ type: 'success', message: res.msg || '操作成功' });
            this.get_taocan_order({
              sjhm: this.$route.query.sjhm
            });
          }
          else {
            showNotify({ type: 'danger', message: res.msg || '操作失败' })
          }
        })
        .catch(() => {
          // on cancel
        });

    }
  },

}
</script>
<style lang="less" scoped>
.fwtc {
  .info {
    margin: 12px 12px;
    border-radius: 10px;
    background: #fff;
    padding: 12px 12px;
    padding-top:0;
    
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .label {
      font-size: 20px;
      color: #333;
    }

    .date {
      font-size: 16px;
      color: red;
    }
  }

  .main {
    margin-top: 24px;

    .box {
      margin: 12px;
      background: #fff;
      padding: 12px;
      border-radius: 10px;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;

        .title {
          font-size: 20px;
          color: #333;
          flex: 1;
        }

        .num {
          margin-left: 12px;
          margin-right: 12px;

          .red {
            font-size: 28px;
            color: red;
          }

          .sum {
            font-size: 18px;
          }
        }

        .btn {
          margin-left: 12px;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:active {
            background: #f4f4f4;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
</style>
